import { EventEmitter, Injectable } from '@angular/core';
import { HttpService, IResponseLower } from "../../shared/cardholders-core/services/http-service.service";
import { AppConfigsService } from "../../config/app-configs.service";
import { Observable } from 'rxjs/Observable';
import { ActivatedRoute } from '@angular/router';
import { ICheckGuidResult } from './models/check-guid-result';
import { ErrorType } from './models/error-message';

@Injectable({
    providedIn: 'root'
})
export class GenericSsoService {
    returnUrl: string;
    guid: string
    baseApi = '';
    error: ErrorType;
    constructor(private httpService: HttpService,
        private appConfigsService: AppConfigsService,
        private activatedRoute: ActivatedRoute) {
        this.baseApi = `${this.appConfigsService.appConfigs.apiUrl}`;
    }

    checkGuidExpiration(guid: string, returnUrl: string): Observable<IResponseLower<ICheckGuidResult>> {
        var request = {
            guid: guid,
            returnUrl: returnUrl
        }
        return this.httpService.postLower(
            `${this.baseApi}/GenericSso/checkGuid`, request);
    }

    CreateIdentifiedGuid(system: string): Observable<IResponseLower<ICheckGuidResult>> {
        var request = {
            system: system,
        }
        return this.httpService.postLower(
            `${this.baseApi}/registered/GenericSso/createGuid`, request );
    }

    updateIdentfication(guid: string): Observable<IResponseLower<string>> {
        var request = {
            guid: guid
        }
        return this.httpService.postLower(
            `${this.baseApi}/registered/GenericSso/updateIdentfication`, request);
    }

    setSsoGuid(guid: string): void {
        const expirationTime = new Date().getTime() + 15 * 60000;
        const ssoLoginData = {
            g: guid,
            ex: expirationTime
        };
        sessionStorage.setItem('ssoGuid', JSON.stringify(ssoLoginData));
    }

    getSsoGuid(): string {
        const ssoLoginData = sessionStorage.getItem('ssoGuid');
        if (!ssoLoginData) {
            return null;
        }
        const { g, ex } = JSON.parse(ssoLoginData);
        if (new Date().getTime() > ex) {
            sessionStorage.removeItem('ssoGuid');
            return null;
        }
        return g;
    }

    setSsoLogin(): void {
        const expirationTime = new Date().getTime() + 15 * 60000;
        const ssoLoginData = {
            v: "1",
            ex: expirationTime
        };
        sessionStorage.setItem('ssoLogin', JSON.stringify(ssoLoginData));
    }

    isLoginWithSsoMode(): boolean {
        const ssoLoginData = sessionStorage.getItem('ssoLogin');
        if (!ssoLoginData) {
            return false;
        }
        const { v, ex } = JSON.parse(ssoLoginData);
        if (new Date().getTime() > ex) {
            sessionStorage.removeItem('ssoLogin');
            return false;
        }
        return v === "1";
    }
}