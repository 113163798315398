import { Component, OnInit, HostListener, ViewEncapsulation, PLATFORM_ID, Inject, ApplicationRef } from '@angular/core';
import {
    ActivatedRoute,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
    RoutesRecognized,
    Scroll
} from '@angular/router';
import { uiIsMobile } from './shared/services/isMobile';
import { isPlatformBrowser, ViewportScroller } from '@angular/common';
import { LoggerService } from './shared/cardholders-core/services/logger.service';
import { LoggerGlobalService } from './shared/cardholders-core/services/logger-global.service';
import { RoutingHistoryService } from './shared/cardholders-core/services/routing-history.service';
import { RoutingHistoryGlobalService } from './shared/cardholders-core/services/routing-history.global.service';
import { LogClickService } from './shared/services/log-click.service';
import { AppConfigsService } from './config/app-configs.service';
import { filter, take } from 'rxjs/operators';
import { UiHelperService } from './shared/cardholders-core/services/ui-helper.service';
import { TokenService } from './shared/cardholders-core/services/token.service';
import { SessionTimeoutService } from './shared/cardholders-core/services/session-timeout.service';
import { VersionService } from './shared/cardholders-core/services/version.service';
import { TesterService } from './shared/cardholders-core/services/tester.service';
import { LinkService } from './shared/services/link.service';
import { LinkRelDef, RelSource } from './shared/cardholders-core/models/link-rel-def';
import { GtmService } from './shared/cardholders-core/services/gtm.service';
import { EloquaService } from './shared/cardholders-core/services/eloqua.service';
import { PrebootService, InitializerService, SeoService, ExcoService } from 'core-max-lib';
import { NoSupportIePopupLibService } from './shared/modules/max-ui-design/no-support-ie-popup/services/no-support-ie-popup-lib.service';
import { CustomTrackingService } from './shared/cardholders-core/services/custom-tracking.service';
import { SeoContentService } from './shared/cardholders-core/services/seoContent.service';
// import { SeoService } from './shared/cardholders-core/services/seo.service';
import { TrusteerSnippetsService } from './shared/cardholders-core/services/trusteer-snippets.service';
import { AuthService } from './shared/cardholders-core/services/auth.service';
import { ScriptHelperService } from './shared/services/script-load-helper.service';
import { ScriptBootService } from './shared/cardholders-core/services/script-boot.service';
import { VConsoleBootService } from './shared/cardholders-core/services/vconsole-boot.service';
import { ChatManagementService } from './shared/services/chat-management.service';
import { VipLogsService } from './shared/cardholders-core/services/vipLogs.service';
import { setA11yLang } from './shared/utils/a11y.utils';

declare var $: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        './app.component.scss',
        // "../../node_modules/font-awesome/css/font-awesome.min.css",
        // '../../node_modules/primeng/resources/primeng.min.css',
        // "../../node_modules/primeicons/primeicons.css",
        // "../../node_modules/primeng/resources/themes/omega/theme.css",
        // '../styles.scss'
        '../styles/app-loader.scss'
    ],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    dialog = false;
    preloadList: LinkRelDef[];
    maxAccountLoading = false;
    private isBrowser: boolean;
    private isInLcLan;
    private isApprovedIpForRecaptcha: boolean;

    constructor(
        public router: Router,
        public route: ActivatedRoute,
        public logger: LoggerService,
        private appConfigsService: AppConfigsService,
        private appRef: ApplicationRef,
        private uiHelper: UiHelperService,
        private gtmService: GtmService,
        private viewportScroll: ViewportScroller,
        private eloquaSrv: EloquaService,
        private prebootSvc: PrebootService,
        private noSupportIePopupLibService: NoSupportIePopupLibService,
        private initializer: InitializerService,
        private customTrackingService: CustomTrackingService,
        private scriptHelper: ScriptHelperService,
        private trusteerSnippetsService: TrusteerSnippetsService,
        private authService: AuthService,
        private tokenService: TokenService,
        @Inject(PLATFORM_ID) private platformId: any,
        private seoService: SeoService,
        private seoContentService: SeoContentService,
        private excoService: ExcoService,
        private versionService: VersionService,
        private testerService: TesterService,
        private sessionTimeoutService: SessionTimeoutService,
        private readonly linkService: LinkService,
        private loggerGlobalService: LoggerGlobalService,
        private routingHistoryService: RoutingHistoryService,
        private routingHistoryGlobalService: RoutingHistoryGlobalService,
        private logClickService: LogClickService,
        private scriptBootService: ScriptBootService,
        private chatManagementService: ChatManagementService,
        private vConsoleBootService: VConsoleBootService,
        private vipLogsService: VipLogsService
    ) {
        this.isBrowser = isPlatformBrowser(platformId);
        linkService.startRouteListener();
        seoService.start();
        seoContentService.start();
        excoService.start();
        this.isInLcLan = this.initializer.getClientContext().IsInMaxLan;
        this.isApprovedIpForRecaptcha = this.initializer.getClientContext().IsApprovedIpForRecaptcha;
        this.preloadList = [
            {
                source: '/assets/fonts/MAX-Medium.woff',
                type: RelSource.font,
                crossorigin: true,
                exstraType: 'font/woff'
            },
            {
                source: '/assets/fonts/MAX-Regular.woff',
                type: RelSource.font,
                crossorigin: true,
                exstraType: 'font/woff'
            },
            {
                source: '/assets/fonts/MAX-Bold.woff',
                type: RelSource.font,
                crossorigin: true,
                exstraType: 'font/woff'
            },
            {
                source: '/styles/fonts/slick.woff',
                type: RelSource.font,
                crossorigin: true,
                exstraType: 'font/woff'
            }
        ];
    }
    @HostListener('window:resize', [])
    onWindowResize(): void {
        if (this.isBrowser) {
            this.setBodyClass();
        }
    }

    ngOnInit(): void {
        setA11yLang('he');
        if (this.isBrowser) {
            this.appRef.isStable
                .pipe(
                    filter((stable) => stable),
                    take(1)
                )
                .subscribe(() => {
                    console.log('stable');
                    this.uiHelper.prebootComplete = true;
                });

            this.setBodyClass();

            // checkNoScript
            if (new URLSearchParams(window.location.search).get('noscript')) {
                this.scriptHelper.skip = true;
            } else {
                setTimeout(() => {
                    this.scriptHelper.skip = !!this.route.snapshot.queryParamMap.get('noscript');
                }, 200);
            }

            //remove unused service worker
            setTimeout(() => {
                if (navigator.serviceWorker) {
                    navigator.serviceWorker.getRegistrations().then(registrations => {
                        for (let registration of registrations) {
                            registration.unregister();
                        }
                    });
                }
            }, 1000);

            window.onbeforeunload = () => {
                this.logger.log('User leaving the application.');
            };

            this.customTrackingService.registerCookie();

            this.vipLogsService.registerCookie();

            this.gtmService.startListening(this.uiHelper.IsMobile(768));

            this.prebootSvc.forceTransitionComplete(this.appConfigsService.appConfigs.serverToClientTransitionTimeout);
            this.prebootSvc.transitionComplete.subscribe((isComplete) => {
                console.log('transitionComplete in app.component');

                if (!isComplete) {
                    console.log('transitionComplete in app.component however is not complete');
                    return;
                }

                if (this.isInLcLan) {
                    return;
                }

            });
            this.noSupportIePopupLibService.init();
            this.scriptBootService.boot();
            this.vConsoleBootService.init();
        }

        this.router.events.subscribe((e) => {
            if (
                e instanceof NavigationStart &&
                (e?.url?.includes('/max-account/personal') ||
                    e?.url?.includes('/total-max-back/personal') ||
                    e?.url?.includes('/activate-card/page/a/sms') ||
                    e?.url?.includes('/dept-payment/personal'))
            ) {
                this.maxAccountLoading = true;
            }
            if((e instanceof NavigationEnd) &&
                e.url.endsWith('/my-max/child/transactions-details')){
                    this.router.navigate(['my-max/child/transactions-details/main']);
                }
            if (
                (e instanceof NavigationEnd || e instanceof NavigationCancel || e instanceof NavigationError) &&
                (e?.url?.includes('/max-account/personal') ||
                    e?.url?.includes('/total-max-back/personal') ||
                    e?.url?.includes('/activate-card/page/a/sms') ||
                    e?.url?.includes('/dept-payment/personal'))
            ) {
                this.maxAccountLoading = false;
            }

            if (e instanceof NavigationEnd && this.isBrowser && !this.skipScrolling()) {
                window.scrollTo(0, 0);
            }

            if (
                e instanceof NavigationEnd &&
                this.authService.isUserAuthenticated() &&
                !this.router.url.includes('customer-loan/personal')
            ) {
                this.trusteerSnippetsService.executeTagmastersEvent('other');
            }

            if(e instanceof NavigationEnd){
                this.gtmService.pushCustomDataLayer({ event: 'historyChange', pagePath: e.urlAfterRedirects });
            }

            if (e instanceof Scroll) {
                if (e.position) {
                    this.viewportScroll.scrollToPosition(e.position);
                }
            }
        });
    }

    skipScrolling(): boolean {
        try {
            return this.router.getCurrentNavigation().extras.state.skipScrolling;
        } catch {
            return false;
        }
    }

    setBodyClass(): void {
        if (window.innerWidth > 1279) {
            document.body.classList.add('desktop');
        } else {
            document.body.classList.remove('desktop');
        }
        if (uiIsMobile()) {
            document.body.classList.add('mobile');
        }
    }
}
