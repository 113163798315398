import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../../../shared/cardholders-core/services/auth.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { AccessibilityService } from '../../../main-layout/services/accessibility.service';
import { Button, Mainbanner } from '../../interfaces/homepage-content.interface';

@Component({
    selector: 'app-main-banner-css',
    templateUrl: './main-banner-css.component.html',
    styleUrls: ['./main-banner-css.component.scss']
})
export class MainBannerCssComponent implements OnInit {
    isBrowser: boolean;
    hasEverLoggedIn: boolean;
    isAnimationPlay = true;

    get content(): Mainbanner {
        return this.hpService.homepageContent?.mainbanner;
    }

    get button(): Button {
        return this.hasEverLoggedIn
            ? this.hpService.homepageContent?.mainbanner.buttonIdentifiedUser
            : this.hpService.homepageContent?.mainbanner.buttonNotIdentifiedUser;
    }

    constructor(
        private hpService: HomepageService,
        public accessibilityService: AccessibilityService,
        private authService: AuthService,
        private uiHelper: UiHelperService,
        private gtmService: GtmService
    ) {
        this.isBrowser = this.uiHelper.isBrowser;
        this.hasEverLoggedIn = this.authService.hasEverLoggedIn();
    }

    ngOnInit(): void {
        this.isAnimationPlay = !this.accessibilityService.noMotionSubject.value;
        if (this.isBrowser) {
            this.gtmService.pushNewWebsiteAnonymous('New - Load', this.hasEverLoggedIn ? 'Load - existing' : 'Load - new');
        }
    }

    onButtonClick(): void {
        this.gtmService.pushNewWebsiteAnonymous('New – Banner click', this.hasEverLoggedIn ? 'Registered site' : 'Order card');
    }

    onActionClick(actionText: string): void {
        this.gtmService.pushNewWebsiteAnonymous('New – Activity click', actionText);
    }

    onAnimationPauseClick(): void {
        this.accessibilityService.toggleNoMotion();
        this.isAnimationPlay = !this.accessibilityService.noMotionSubject.value;
    }
}
