<div class="offer-carousel-container">
    <div class="carousel-wrap swipe-element">
        <div #carouselItem (click)="expandItem(i)" (ngInit)="initCarouselItems()" class="offer-carousel-item"
            *ngFor="let item of offersList; let i = index"
            [ngClass]="this.initialItemsIndex === i ? 'item-open text-enter' : ''">
            <div class="item-inner">

                <div class="item-left" [style.backgroundColor]="'#' + item.color">
                    <div class="text-wrap" [style.color]="'#' + item.textColor">
                        <h1 aria-level="3" aria-role="heading" [innerHtml]="item.title | sanitizeHtml">
                        </h1>
                        <p [innerHtml]="item.subTitle | sanitizeHtml">
                        </p>
                        <a (click)="onActionClick(item)" [href]="item?.cTabutton?.url">
                            <button [style.color]="'#' + item.textColor">
                                {{ item.cTabutton.text }}
                                <img src="assets/images/new-homepage/markting-arrow.svg" />
                            </button>
                        </a>
                        <span class="disclaimer" [innerHtml]="item.disclaimer | sanitizeHtml"></span>
                    </div>
                </div>

                <div class="item-right" [style.backgroundColor]="'#' + item.color">
                    <div class="img-element" *ngIf="!item.video.endsWith('.mp4') && !item.video.endsWith('.json')"
                        [ngStyle]="{ backgroundImage: 'url(' + item.image + ')' }"></div>
                    <div class="item-nav">
                        <button [attr.aria-label]="getAriaLabel(i)" [disabled]="this.itemIndex === i">
                            <img src="/assets/images/new-homepage/carousel-nav-icon.svg" alt="Expand" />
                        </button>
                    </div>

                    <video *ngIf="item.video.endsWith('.mp4')" #myVideo muted playsinline loop preload="auto" autoplay>
                        <source [src]="item.video" type="video/mp4">
                        Your browser does not support the video tag.
                    </video>

                    <ng-container *ngIf="item.video.endsWith('.json')">
                        <lottie-animation-view (animCreated)="initLottie($event)" [options]="{
                            autoplay: false,
                            loop: true,
                            path: item.video
                        }">
                        </lottie-animation-view>
                    </ng-container>
                </div>

            </div>
        </div>
    </div>
</div>