
import { Component, ElementRef, ViewChildren, QueryList, ViewChild, HostListener } from '@angular/core';
import { Offer } from '../../interfaces/homepage-content.interface';
import { HomepageService } from '../../../homepage/services/homepage.service';
import { UiHelperService } from '../../../../shared/cardholders-core/services/ui-helper.service';
import { GtmService } from '../../../../shared/cardholders-core/services/gtm.service';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss']
})
export class OfferCarouselComponent {
  isMobile: boolean;
  offersList: Offer[];

  constructor(private hpService: HomepageService, private uiHelper: UiHelperService, private gtmService: GtmService, private el: ElementRef) {
    this.isMobile = this.uiHelper.IsMobile(768);
  }

  @ViewChild('myVideo', { static: false }) myVideo!: ElementRef;
  @ViewChildren('carouselItem') carouselItems!: QueryList<ElementRef>; // Get all carousel items
  itemsLength = 0;
  items: HTMLElement[] = [];
  lottieInstance: any;
  initialItemsIndex = 0;
  userInteracted = false;
  itemIndex = this.initialItemsIndex;
  startX = 0;
  startY = 0;
  distX = 0;
  distY = 0;
  videoPlaying: boolean = false;

  ngAfterViewInit(): void {
    this.hpService.loadHomepageContent().then((data: any) => {
      const { offers: { offersListMobile, offersListDesktop } } = data;
      this.offersList = this.isMobile ? offersListMobile : offersListDesktop;
      this.initCarousel();
    })
  }

  initCarousel(): void {
    // Set up touch events for swipe
    const swipeElement = this.el.nativeElement.querySelector('.swipe-element');
    this.setupSwipeEvents(swipeElement);
  }

  @HostListener('window:scroll', ['$event'])
  onScroll() {
    if (this.myVideo) {
      const videoElement = this.myVideo.nativeElement as HTMLVideoElement;
      const targetPosition = videoElement.getBoundingClientRect().top + window.scrollY;
      const scrollPosition = window.scrollY + window.innerHeight;
      if (scrollPosition >= targetPosition) {
        if (!this.videoPlaying) {
          this.playVideo(videoElement);
        }
        setTimeout(() => {
          if (!this.userInteracted) {
            this.expandItem(1)
          }
        }, 4000);
      }
    }
  }


  // Function to play the video
  playVideo(videoElement: HTMLVideoElement): void {
    videoElement.muted = true;
    videoElement.play().then(e => {
      this.videoPlaying = true;
    }).catch((error) => {
      console.warn('Video play failed:', error);
    });
  }

  getAriaLabel(i: number) {
    return this.itemIndex === i ? "" : this.itemIndex > i ? "לשקופית הקודמת" : this.itemIndex < i ? "לשקופית הבאה" : "";
  }

  onActionClick(offer: Offer): void {
    this.gtmService.pushNewWebsiteAnonymous('New - Offers', `Click - ${offer?.title}`);
  }

  onArrowNavigation(): void {
    this.gtmService.pushNewWebsiteAnonymous('New - Offers', 'Desktop - Click on arrow');
  }

  onItemSwipe(): void {
    this.gtmService.pushNewWebsiteAnonymous('New - Offers', 'Mobile - Swipe');
  }

  initCarouselItems() {
    this.items = this.carouselItems.map(item => item.nativeElement);
  }

  expandItem(currentIndex: number): void {
    this.userInteracted = true;
    this.itemIndex = currentIndex;
    this.items.forEach((item, index) => {
      if (index === currentIndex) {
        item.classList.add('item-open');
        setTimeout(() => {
          item.classList.add('text-enter');
        }, 200);
        this.onArrowNavigation();

        const itemConfig = this.offersList[index];
        this.playOrPause(itemConfig);

      } else {
        item.classList.remove('item-open');
        item.classList.remove('text-enter');
      }
    });

  }

  playOrPause(itemConfig: any) {
    const videoElement = this.myVideo.nativeElement as HTMLVideoElement;
    if (itemConfig?.video.endsWith('.mp4')) {
      videoElement?.play();
    } else {
      videoElement?.pause();
    }

    if (itemConfig.video.endsWith('.json')) {
      this.lottieInstance?.play();
    } else {
      this.lottieInstance?.pause();
    }
  }

  nextItem(): void {
    this.userInteracted = true;
    if (this.items[this.itemIndex + 1]) {
      const item = this.items[this.itemIndex];
      item.classList.remove('item-open');
      item.classList.remove('text-enter');
      this.itemIndex++;
      this.items[this.itemIndex].classList.add('item-open');
      const itemConfig = this.offersList[this.itemIndex];
      this.playOrPause(itemConfig);
      setTimeout(() => {
        this.items[this.itemIndex].classList.add('text-enter');
      }, 200);
      this.onItemSwipe();
    }
  }

  prevItem(): void {
    this.userInteracted = true;
    if (this.itemIndex - 1 >= 0) {
      const item = this.items[this.itemIndex];
      item.classList.remove('item-open');
      item.classList.remove('text-enter');
      this.itemIndex--;
      this.items[this.itemIndex].classList.add('item-open');
      const itemConfig = this.offersList[this.itemIndex];
      this.playOrPause(itemConfig);
      setTimeout(() => {
        this.items[this.itemIndex].classList.add('text-enter');
      }, 200);
      this.onItemSwipe();
    }
  }

  initLottie(lottieObj: any) {
    this.lottieInstance = lottieObj;
  }

  setupSwipeEvents(swipeElement: HTMLElement): void {
    swipeElement.addEventListener('touchstart', (e: TouchEvent) => {
      const touch = e.touches[0];
      this.startX = touch.clientX;
      this.startY = touch.clientY;
    });

    swipeElement.addEventListener('touchmove', (e: TouchEvent) => {
      const touch = e.touches[0];
      this.distX = touch.clientX - this.startX;
      this.distY = touch.clientY - this.startY;
    });

    swipeElement.addEventListener('touchend', (e: TouchEvent) => {
      const swipeThreshold = 50; // Minimum swipe distance in pixels

      if (Math.abs(this.distX) > swipeThreshold && Math.abs(this.distY) < 100) {
        if (this.distX > 0) {
          this.nextItem();
        } else {
          this.prevItem();
        }
      }

      // Reset distances
      this.distX = 0;
      this.distY = 0;
    });
  }
}
