import { Route } from '@angular/router';
import { MainLayoutComponent } from './modules/main-layout/main-layout.component';
import { WelcomeHomeComponent } from './modules/homepage/components/welcome-home/welcome-home.component';
import { MainComponent } from './modules/new-homepage/components/main/main.component';
import { PersonalHomeComponent } from './modules/homepage/components/personal-home/personal-home.component';
import { Roles } from './shared/cardholders-core/services/auth.service';
import { AuthGuard } from './shared/cardholders-core/guards/auth.guard';
import { AuthLoginRedirectComponent } from './components/auth-login-redirect/auth-login-redirect.component';
import { NavigateSsoComponent } from './components/navigate-sso/navigate-sso.component';
import { SsoGeneralComponent } from './modules/sso-general/sso-general.component';
import { LandingPageComponent } from './modules/total-max-back/components/convert-page/landing-page/landing-page.component';
import { MessageReceiveComponent } from './modules/main-layout/message-receive/message-receive.component';
import { SsoWithMaxComponent } from "./modules/generic-sso/components/sso-with-max/sso-with-max.component";

export const appRoutes: Route[] = [
    {
        path: 'homepage/welcome',
        pathMatch: 'full',
        redirectTo: '/'
    },
    {
        path: 'error',
        pathMatch: 'full',
        redirectTo: 'errornew'
    },
    {
        path: '',
        canActivateChild: [AuthGuard],
        data: {
            authConfig: {
                notAuthorizedRedirectUrl: 'auth-login-redirect',
                notAuthorizedSendReturnUrl: true,
                notAuthorizedSkipLocation: true
            },
            sessionTimeoutConfig: {
                sessionTimeout: true,
                sessionLogoutRedirectRoute: '/',
                sessionExpiredRedirectRoute: 'session-expired'
            }
        },
        children: [
            {
                path: 'general',
                loadChildren: () => import('./modules/general/general.module').then(m => m.GeneralModule)
            },
            {
                path: 'home',
                loadChildren: () => import('./modules/join-home/join-home.module').then(m => m.JoinHomeModule),
                data: { sessionTimeoutConfig: { sessionLogoutRedirectRoute: 'home' } }
            },
            {
                path: 'sso-general',
                component: SsoGeneralComponent
            },

            {
                path: 'fox-consent',
                loadChildren: () => import('./modules/fox-consent/fox-consent.module').then(m => m.FoxConsentModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'sso-navigator',
                loadChildren: () => import('./modules/generic-sso/generic-sso.module').then(m => m.GenericSsoModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false },
             
                }
            },
            {
                path: 'sso-with-max',
                component: SsoWithMaxComponent
            },

            {
                path: '',
                component: MainLayoutComponent,
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionLogoutRedirectRoute: '/' }
                },
                children: [
                    {
                        path: '',
                        component: MainComponent,
                        data: {
                            isHeaderTransparent: true,
                            minimalHedaer: true,
                            sessionTimeoutConfig: { sessionTimeout: false },
                            authConfig: { authenticatedRedirectUrl: '/homepage/personal' }
                        }
                    },



                    {
                        path: 'homepage/personal',
                        component: PersonalHomeComponent,
                        data: {
                            authConfig: {
                                roles: [Roles.REG, Roles.GALI, Roles.WS],
                                notAuthenticatedRedirectUrl: '/'
                            }
                        }
                    },
                    {
                        path: 'homepage',
                        redirectTo: '/homepage/personal',
                        pathMatch: 'full'
                    }
                ]
            },
            // {
            //    path: 'homepage',
            //    loadChildren: './modules/homepage/homepage.module#HomepageModule',
            //    data: {
            //        useSpinner: false
            //    }
            // },
            {
                path: 'agreements/personal',
                loadChildren: () => import('./modules/agreements/agreements.module').then(m => m.AgreementsModule),
                data: {
                    useSpinner: false,
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },

            {
                path: 'get-insurance/wellcome',
                loadChildren: () =>
                    import('./modules/get-insurance/get-insurance.module').then(m => m.GetInsuranceModule),
                data: {
                    useSpinner: false
                }
            },
            {
                path: 'insurance/personal',
                loadChildren: () => import('./modules/insurance/insurance.module').then(m => m.InsuranceModule),
                data: {
                    useSpinner: false,
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'remove-insurance',
                loadChildren: () =>
                    import('./modules/remove-insurance/remove-insurance.module').then(m => m.RemoveInsuranceModule),
                data: {
                    useSpinner: false,
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'insurance/newsletter-unsubscribe',
                loadChildren: () =>
                    import('./modules/insurance-newsletter-unsubscribe/insurance-newsletter-unsubscribe.module').then(
                        m => m.InsuranceNewsletterUnsubscribeModule
                    ),
                data: {
                    useSpinner: false
                }
            },
            {
                path: 'max-bank-access',
                loadChildren: () => import('./modules/bank-access/bank-access.module').then(m => m.BankAccessModule),
                data: { useSpinner: false }
            },
            {
                path: 'max-commbox-access',
                loadChildren: () =>
                    import('./modules/commbox-login/commbox-login.module').then(m => m.CommboxLoginModule),
                data: { useSpinner: false }
            },
            {
                path: 'free-dan-lounge',
                loadChildren: () =>
                    import('./modules/free-dan-lounge/free-dan-lounge.module').then(x => x.FreeDanLoungeModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            // <editor-fold desc="Yevgeny modules">
            {
                path: 'update-flight',
                loadChildren: () =>
                    import('./modules/update-flight-details/update-flight-details.module').then(
                        m => m.UpdateFlightDetailsModule
                    ),
                data: {
                    minimalHedaer: true,
                    useSpinner: true,
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.WS] //Roles.ADMIN
                    }
                }
            },
            // </editor-fold>
            {
                path: 'check-authorization-list',
                loadChildren: () =>
                    import('./modules/check-authorization-list/check-authorization-list.module').then(
                        m => m.CheckAuthorizationListModule
                    ),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: {
                        sessionLogoutRedirectRoute: 'check-authorization-list',
                        sessionExpiredRedirectRoute: 'check-authorization-list'
                    }
                }
            },
            {
                path: ':dictionary-type/general-dictionary',
                loadChildren: () =>
                    import('./modules/dictionary-dynamic/dictionary-dynamic.module').then(m => m.DictionaryLibModule),
                data: { minimalHedaer: true }
            },
            {
                path: 'suspicious-transactions',
                loadChildren: () =>
                    import('./modules/suspicious-transactions/suspicious-transactions.module').then(
                        m => m.SuspiciousTransactionsModule
                    ),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: {
                        sessionLogoutRedirectRoute: 'suspicious-transactions',
                        sessionExpiredRedirectRoute: 'suspicious-transactions'
                    }
                }
            },
            {
                path: 'change-abroad-billing-cycle/personal',
                loadChildren: () =>
                    import('./modules/change-abroad-billing-cycle/change-abroad-billing-cycle.module').then(
                        m => m.ChangeAbroadBillingCycleModule
                    ),
                data: { useSpinner: false }
            },
            {
                path: 'transaction-details/personal',
                loadChildren: () =>
                    import('./modules/transactions/transaction-details/transaction-details.module').then(
                        m => m.TransactionDetailsModule
                    ),
                data: { useSpinner: false, usePrePredict: true }
            },
            {
                path: 'transaction-deny/personal',
                loadChildren: () =>
                    import('./modules/transactions/transaction-deny/transaction-deny.module').then(
                        m => m.TransactionDenyModule
                    ),
                data: { useSpinner: false }
            },
            {
                path: 'digital-payments/personal',
                loadChildren: () =>
                    import('./modules/digital-payments/digital-payments.module').then(m => m.DigitalPaymentsModule),
                data: {
                    useSpinner: false,
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'cards/giftcards',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    lobbyName: 'giftcards',
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'cards/payments',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    lobbyName: 'payments',
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'cards/:lobby-name/lobby',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'credit-multi/personal',
                loadChildren: () => import('./modules/credit-multi/credit-multi.module').then(m => m.CreditMultiModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'loans/:lobby-name/lobby',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'insurance/:lobby-name/lobby',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'cards',
                pathMatch: 'full',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    lobbyName: 'cards',
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'cards',
                loadChildren: () => import('./modules/cards/cards.module').then(m => m.CardsModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false, sessionLogoutRedirectRoute: 'cards' },
                    usePrePredict: true
                }
            },
            {
                path: 'tracker',
                loadChildren: () => import('./modules/tracker/tracker.module').then(m => m.TrackerModule),
                data: { sessionTimeoutConfig: { sessionTimeout: false, sessionLogoutRedirectRoute: 'tracker' } }
            },
            {
                path: 'wizard',
                loadChildren: () => import('./modules/wizard/wizard.module').then(m => m.WizardModule),
                data: { sessionTimeoutConfig: { sessionTimeout: false, sessionLogoutRedirectRoute: 'wizard' } }
            },
            // {
            //    path: 'loans',
            //    loadChildren: './modules/loans/loans.module#LoansModule',
            //    data: { sessionTimeoutConfig: { sessionLogoutRedirectRoute: 'loans' }, usePrePredict: true }
            // },
            {
                path: 'p2p-payments',
                loadChildren: () => import('./modules/p2p-payments/p2p-payments.module').then(m => m.P2PPaymentsModule),
                data: { sessionTimeoutConfig: { sessionTimeout: false } }
            },
            {
                path: 'contact-us',
                loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'contactus',
                loadChildren: () => import('./modules/contact-us/contact-us.module').then(m => m.ContactUsModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'refer-a-friend',
                loadChildren: () =>
                    import('./modules/friend-brings-friend/refer-a-friend.module').then(m => m.ReferAFriendModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'server8',
                loadChildren: () => import('./modules/server8/server8.module').then(m => m.Server8Module),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false },
                    useSpinner: false,
                    eve: '8'
                }
            },
            {
                path: 'server10',
                loadChildren: () => import('./modules/server8/server8.module').then(m => m.Server8Module),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false },
                    useSpinner: false,
                    eve: '10'
                }
            },
            {
                path: 'sitemapv2.xml',
                loadChildren: () => import('./modules/sitemap/sitemap.module').then(m => m.SitemapModule)
            },
            {
                path: 'biometric-login',
                loadChildren: () =>
                    import('./modules/biometric-login/biometric-login.module').then(m => m.BiometricLoginModule),
                data: { sessionTimeoutConfig: { sessionTimeout: false } }
            },
            {
                path: 'auth-login-redirect',
                component: AuthLoginRedirectComponent
            },
            {
                path: 'navigate-sso',
                component: NavigateSsoComponent
            },
            {
                path: 'loginwithmax',
                loadChildren: () =>
                    import('./modules/login-with-max/login-with-max.module').then(m => m.LoginWithMaxModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'login',
                loadChildren: () => import('./modules/login/login-page/login-page.module').then(m => m.LoginPageModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'open-banking',
                loadChildren: () => import('./modules/open-banking/open-banking.module').then(m => m.OpenBankingModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'user-transfer/personal',
                loadChildren: () =>
                    import('./modules/login/user-transfer/user-transfer.module').then(m => m.UserTransferModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'loans',
                loadChildren: () => import('./modules/loans-lobby/loans-lobby.module').then(m => m.LoansLobbyModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'landing',
                loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'skymaxhistory',
                loadChildren: () => import('./modules/sky-max/sky-max.module').then(m => m.SkyMaxModule),
                data: {
                    useSpinner: false,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'customer-loan/personal',
                loadChildren: () =>
                    import('./modules/customer-loan/customer-loan.module').then(m => m.CustomerLoanModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'max-back/personal',
                loadChildren: () => import('./modules/max-back/max-back.module').then(m => m.MaxBackModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'remove-dapap/personal',
                loadChildren: () => import('./modules/remove-dapap/remove-dapap.module').then(m => m.RemoveDapapModule),
                data: {
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'fix-charge/personal',
                loadChildren: () => import('./modules/fix-charge/fix-charge.module').then(m => m.FixChargeModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'max-account/anonymous',
                loadChildren: () =>
                    import('./modules/max-account-anonymous/max-account-anonymous.module').then(
                        m => m.MaxAccountAnonymousModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'inbox-and-mailing/personal',
                loadChildren: () =>
                    import('./modules/inbox-and-mailing/inbox-and-mailing.module').then(m => m.InboxAndMailingModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        // tslint:disable-next-line: max-line-length
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.CONTACT_AUTHORIZATION
                        ]
                    }
                }
            },

            {
                path: 'max-account/personal',
                loadChildren: () => import('./modules/max-account/max-account.module').then(m => m.MaxAccountModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'sms-mail-registration',
                loadChildren: () =>
                    import('./modules/sms-mail-registration/sms-mail-registration.module').then(
                        m => m.SmsMailRegistrationModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'past-monthly-billing-layout/personal',
                loadChildren: () =>
                    import('./modules/past-monthly-billing-layout/divide-retrospect-obligation.module').then(
                        m => m.DivideRetrospectObligationModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'magazine',
                loadChildren: () => import('./modules/magazine/magazine.module').then(m => m.MagazineModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'business/magazine',
                loadChildren: () => import('./modules/magazine/magazine.module').then(m => m.MagazineModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: ':magazine-category/magazine',
                loadChildren: () => import('./modules/magazine/magazine.module').then(m => m.MagazineModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'business/:magazine-category/magazine',
                loadChildren: () => import('./modules/magazine/magazine.module').then(m => m.MagazineModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: ':magazine-category/magazine/:magazine-article',
                loadChildren: () =>
                    import('./modules/magazine/magazine-article/magazine-article.module').then(
                        m => m.MagazineArticleModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'business/:magazine-category/magazine/:magazine-article',
                loadChildren: () =>
                    import('./modules/magazine/magazine-article/magazine-article.module').then(
                        m => m.MagazineArticleModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: ':product-name/pages/:magazine-article',
                loadChildren: () =>
                    import('./modules/magazine/general-article/general-article.module').then(
                        m => m.GeneralArticleModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'business/:product-name/pages/:magazine-article',
                loadChildren: () =>
                    import('./modules/magazine/general-article/general-article.module').then(
                        m => m.GeneralArticleModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'business/pay',
                loadChildren: () =>
                    import("./modules/slika-landing/slika-landing.module").then(
                        (m) => m.SlikaLandingModule
                    ),
                data: {
                    minimalHedaer: true,
                    useSpinner: false
                }
            },
            {
                path: 'register-website',
                loadChildren: () =>
                    import('./modules/register-website/register-website.module').then(m => m.RegisterWebsiteModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'renew-password',
                loadChildren: () =>
                    import('./modules/recover-password/recover-password.module').then(m => m.RecoverPasswordModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'benefits/bizplus',
                loadChildren: () =>
                    import('./modules/benefits-plus/benefits-plus.module').then(m => m.BenefitsPlusModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'benefits-orders/history',
                loadChildren: () =>
                    import('./modules/benefits-history/benefits-history.module').then(m => m.BenefitsHistoryModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'benefits',
                loadChildren: () => import('./modules/benfits/benfits.module').then(m => m.BenfitsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'benefit-order',
                loadChildren: () =>
                    import('./modules/benefit-order/benefit-order.module').then(m => m.BenefitOrderModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },

            // {
            //    path: 'lead/:product-name',
            //    loadChildren: './modules/product/product.module#ProductModule',
            //    data: {
            //        sessionTimeoutConfig: { sessionTimeout: false }
            //    }
            // },
            {
                path: ':lobby-name/lobby',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'insurance',
                loadChildren: () =>
                    import('./modules/general-lobby/general-lobby.module').then(m => m.GeneralLobbyModule),
                data: {
                    lobbyName: 'insurance',
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'home-insurance',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'home-insurance'
                }
            },
            {
                path: 'home-insurance/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'home-insurance'
                }
            },
            {
                path: 'home-insurance/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'home-insurance'
                }
            },
            {
                path: 'business-insurance',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business-insurance',
                    isBusinessPage: true
                }
            },
            {
                path: 'business-insurance/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business-insurance',
                    isBusinessPage: true
                }
            },
            {
                path: 'business-insurance/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business-insurance',
                    isBusinessPage: true
                }
            },
            {
                path: 'travel-insurance',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'travel-insurance'
                }
            },
            {
                path: 'travel-insurance/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'travel-insurance'
                }
            },
            {
                path: 'travel-insurance/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'travel-insurance'
                }
            },
            {
                path: 'car-insurance',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'car-insurance'
                }
            },
            {
                path: 'car-insurance/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'car-insurance'
                }
            },
            {
                path: 'car-insurance/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'car-insurance'
                }
            },
            {
                path: 'mortgage-insurance',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'mortgage-insurance'
                }
            },
            {
                path: 'mortgage-insurance/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'mortgage-insurance'
                }
            },
            {
                path: 'mortgage-insurance/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'mortgage-insurance'
                }
            },
            {
                path: 'loans/calc',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'calc'
                }
            },
            {
                path: 'loans/fixcharge',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'fixcharge'
                }
            },
            {
                path: 'loans/cars',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false },
                    pageName: 'cars'
                }
            },
            {
                path: 'loans/cars/toyota',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false },
                    pageName: 'toyota'
                }
            },
            { path: 'scav', loadChildren: () => import('./modules/scav/scav.module').then(m => m.ScavModule) },
            { path: 'scav2', loadChildren: () => import('./modules/scav2/scav2.module').then(m => m.Scav2Module) },
            {
                path: 'external-ocr',
                loadChildren: () => import('./modules/external-ocr/external-ocr.module').then(m => m.ExternalOcrModule)
            },
            {
                path: 'material',
                loadChildren: () => import('./modules/material/material.module').then(m => m.MaterialModule)
            },
            { path: 'scav', loadChildren: () => import('./modules/scav/scav.module').then(m => m.ScavModule) },
            {
                path: 'material',
                loadChildren: () => import('./modules/material/material.module').then(m => m.MaterialModule)
            },
            {
                path: 'instant-issuing',
                loadChildren: () =>
                    import('./modules/instant-issuing/instant-issuing.module').then(m => m.InstantIssuingModule)
            },
            {
                path: 'instant-issuing-order',
                loadChildren: () =>
                    import('./modules/instant-issuing-order/instant-issuing-order.module').then(
                        m => m.InstantIssuingOrderModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'digital-forms',
                loadChildren: () =>
                    import('./modules/digital-forms/digital-forms.module').then(m => m.DigitalFormsModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'delivery-upload-files',
                loadChildren: () => import('./modules/upload-files/upload-files.module').then(m => m.UploadFilesModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'bdi',
                loadChildren: () => import('./modules/bdi/bdi.module').then(m => m.BdiModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'digital-transfer',
                loadChildren: () =>
                    import('./modules/digital-transfer/digital-transfer.module').then(m => m.DigitalTransferModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'auth',
                loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
                data: { useSpinner: false, sessionTimeoutConfig: { sessionTimeout: true } }
            },
            {
                path: 'identify-user-for-call-center',
                loadChildren: () =>
                    import('./modules/identification/identification.module').then(m => m.IdentificationModule),
                data: { useSpinner: false, sessionTimeoutConfig: { sessionTimeout: true } }
            },
            { path: 'sso', loadChildren: () => import('./modules/sso/sso.module').then(m => m.SsoModule) },

            {
                path: 'ecom-registration/personal',
                loadChildren: () =>
                    import('./modules/ecom-registration/ecom-registration.module').then(m => m.EcomRegistrationModule)
            },
            {
                path: 'roladin-benefit/personal',
                loadChildren: () =>
                    import('./modules/roladin-benefit/roladin-benefit.module').then(m => m.RoladinBenefitModule)
            },
            {
                path: 'virtual-gift-card',
                loadChildren: () =>
                    import('./modules/virtual-gift-card/virtual-gift-card.module').then(m => m.VirtualGiftCardModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'gift-card',
                loadChildren: () => import('./modules/gift-card/gift-card.module').then(m => m.GiftCardModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'unfreeze-direct-debit/personal',
                loadChildren: () =>
                    import('./modules/unfreeze-direct-debit/unfreeze-direct-debit.module').then(
                        m => m.UnfreezeDirectDebitModule
                    ),
                data: {
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'monthly-billing-layout/personal',
                loadChildren: () =>
                    import('./modules/monthly-billing-layout/monthly-billing-layout.module').then(
                        m => m.MonthlyBillingLayoutModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'request-pin-code',
                loadChildren: () =>
                    import('./modules/pin-code/request-pin-code/request-pin-code.module').then(
                        m => m.RequestPinCodeModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'receive-pin-code',
                loadChildren: () =>
                    import('./modules/pin-code/receive-pin-code/receive-pin-code.module').then(
                        m => m.ReceivePinCodeModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'block-card',
                loadChildren: () => import('./modules/block-card/block-card.module').then(m => m.BlockCardModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'ivr-login',
                loadChildren: () => import('./modules/ivr-login/ivr-login.module').then(m => m.IvrLoginModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'control/personal',
                loadChildren: () => import('./modules/controls/controls.module').then(m => m.ControlsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'marketing-agreement-popup/personal',
                loadChildren: () =>
                    import('./modules/marketing-agreement-popup/marketing-agreement-popup.module').then(
                        m => m.MarketingAgreementPopupModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'credit-limit/personal',
                loadChildren: () => import('./modules/credit-limit/credit-limit.module').then(m => m.CreditLimitModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'change-billing-cycle/personal',
                loadChildren: () =>
                    import('./modules/change-billing-cycle/change-billing-cycle.module').then(
                        m => m.ChangeBillingCycleModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'credit-product-data',
                loadChildren: () =>
                    import('./modules/credit-product-data/credit-product-data.module').then(
                        m => m.CreditProductDataModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'credit-data-with-agent',
                loadChildren: () =>
                    import('./modules/credit-data-with-agent/credit-data-with-agent.module').then(
                        m => m.CreditDataWithAgentModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'credit-data-issue-card',
                loadChildren: () =>
                    import('./modules/credit-data-issue-card/credit-data-issue-card.module').then(
                        m => m.CreditDataIssueCardModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'car-loan-uploadfiles',
                loadChildren: () =>
                    import('./modules/car-loan-uploadfiles/car-loan-uploadfiles.module').then(
                        m => m.CarLoansUploadfilesModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'card-fees-refund',
                loadChildren: () =>
                    import('./modules/card-fees-refund/card-fees-refund.module').then(m => m.CardFeesRefundModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'threeds',
                loadChildren: () => import('./modules/threeDS/threeDS.module').then(m => m.ThreeDSModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'spread-transaction-credit/personal',
                loadChildren: () =>
                    import('./modules/spread-transaction-credit/spread-transaction-credit.module').then(
                        m => m.SpreadTransactionCreditModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'spread-transaction/personal',
                loadChildren: () =>
                    import('./modules/spread-transaction/spread-transaction.module').then(
                        m => m.SpreadTransactionModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'generic-url-for-webview/close-web-view',
                loadChildren: () =>
                    import('./modules/generic-url-for-webview/generic-url-for-webview.module').then(
                        m => m.GenericUrlForWebviewModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'agree-direct-mail/personal',
                loadChildren: () =>
                    import('./modules/agree-direct-mail/agree-direct-mail.module').then(m => m.AgreeDirectMailModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'ecobill/personal',
                loadChildren: () => import('./modules/ecobill/ecobill.module').then(m => m.EcobillModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'join-bog/personal',
                loadChildren: () => import('./modules/join-bog/join-bog.module').then(m => m.JoinBogModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'sign-personal-update/personal',
                loadChildren: () =>
                    import('./modules/sign-personal-update/sign-personal-update.module').then(
                        m => m.SignPersonalUpdateModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'agree-whatsapp/personal',
                loadChildren: () =>
                    import('./modules/agree-whatsapp/agree-whatsapp.module').then(m => m.AgreeWhatsappModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'vgc-management',
                loadChildren: () =>
                    import('./modules/vgc-mangement/vgc-mangement.module').then(m => m.VgcMangementModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: true,
                        sessionExpiredRedirectRoute: 'https://kadam.leumi-card.co.il'
                    }
                }
            },
            {
                path: 'direct-debit/personal',
                loadChildren: () => import('./modules/direct-debit/direct-debit.module').then(m => m.DirectDebitModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'car-loans-signing',
                loadChildren: () =>
                    import('./modules/car-loans-signing/car-loans-signing.module').then(m => m.CarLoansSigningModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'application-navigation',
                loadChildren: () =>
                    import('./modules/application-navigation/application-navigation.module').then(
                        m => m.ApplicationNavigationModule
                    )
            },
            {
                path: 'search',
                loadChildren: () => import('./modules/search/search.module').then(m => m.SearchModule)
            },
            {
                path: 'loans/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'loans'
                }
            },
            {
                path: 'loans/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'loans'
                }
            },
            {
                path: 'loans/:paramX/:paramY/:paramZ',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'loans'
                }
            },
            {
                path: 'business/loans/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business,loans'
                }
            },
            {
                path: 'business/loans/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business,loans'
                }
            },
            {
                path: 'business/loans/:paramX/:paramY/:paramZ',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business,loans'
                }
            },
            {
                path: 'business/pay/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business,pay'
                }
            },
            {
                path: 'business/pay/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business,pay'
                }
            },
            {
                path: 'business/pay/:paramX/:paramY/:paramZ',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'business,pay'
                }
            },
            {
                path: 'cards/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'cards'
                }
            },
            {
                path: 'cards/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'cards'
                }
            },
            {
                path: 'giftcards',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'giftcards'
                }
            },
            {
                path: 'giftcards/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'giftcards'
                }
            },
            {
                path: 'giftcards/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'giftcards'
                }
            },
            {
                path: 'saving/personal',
                loadChildren: () => import('./modules/saving/saving.module').then(m => m.SavingModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'teachers-organization',
                loadChildren: () =>
                    import('./modules/teachers-organization/teachers-organization.module').then(
                        m => m.TeachersOrganizationModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'foreign-currency-pocket/personal',
                loadChildren: () =>
                    import('./modules/foreign-currency-pocket/foreign-currency-pocket.module').then(
                        m => m.ForeignCurrencyPocketModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'sms-mobile-alerts/personal',
                loadChildren: () =>
                    import('./modules/sms-mobile-alerts/sms-mobile-alerts.module').then(m => m.SmsMobileAlertsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'saving/:paramX',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'saving'
                }
            },
            {
                path: 'saving/:paramX/:paramY',
                loadChildren: () => import('./modules/product/product.module').then(m => m.ProductModule),
                data: {
                    sessionTimeoutConfig: {
                        sessionTimeout: false
                    },
                    pageName: 'saving'
                }
            },
            {
                path: 'customer-identification',
                loadChildren: () =>
                    import('./modules/customer-identification/customer-identification.module').then(
                        m => m.CustomerIdentificationModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'fast-digital-loan',
                loadChildren: () => import('./modules/no-card-loan/no-card-loan.module').then(m => m.NoCardLoanModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'mygift/personal',
                loadChildren: () => import('./modules/my-gift/my-gift.module').then(m => m.MyGiftModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'fair-credit/personal',
                loadChildren: () => import('./modules/fair-credit/fair-credit.module').then(m => m.FairCreditModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'cancel-permanent-order/personal',
                loadChildren: () =>
                    import('./modules/cancel-permanent-order/cancel-permanent-order.module').then(
                        m => m.CancelPermanentOrderModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'redemption-process/personal',
                loadChildren: () =>
                    import('./modules/redemption-process/redemption-process.module').then(
                        m => m.RedemptionProcessModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'deceasedrelative',
                loadChildren: () =>
                    import('./modules/request-for-details-page/request-for-details-page.module').then(
                        m => m.RequestForDetailsPageModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'activate-card',
                loadChildren: () =>
                    import('./modules/activate-card/activate-card.module').then(m => m.ActivateCardModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'traklin-dan/personal',
                loadChildren: () => import('./modules/traklin-dan/traklin-dan.module').then(m => m.TraklinDanModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'saving-homepage/personal',
                loadChildren: () =>
                    import('./modules/saving-homepage/saving-homepage.module').then(m => m.SavingHomepageModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'my-max',
                loadChildren: () => import('./modules/my-max/my-max.module').then(m => m.MyMaxModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'gift-card-transactions',
                loadChildren: () =>
                    import('./modules/gift-card-transactions/gift-card-transactions.module').then(m => m.GiftCardTransactionsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'spread-transactions-list/personal',
                loadChildren: () =>
                    import('./modules/spread-transactions-list/spread-transactions-list.module').then(
                        m => m.SpreadTransactionsListModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'remove-newsletter',
                loadChildren: () =>
                    import('./modules/remove-newsletter/remove-newsletter.module').then(m => m.RemoveNewsletterModule),
                data: {
                    minimalHedaer: true
                }
            },
            {
                path: 'gift-card-load',
                loadChildren: () =>
                    import('./modules/gift-card-load/gift-card-load.module').then(m => m.GiftCardLoadModule),
                data: {
                    minimalHedaer: true
                }
            },
            {
                path: 'balance-approval/personal',
                loadChildren: () =>
                    import('./modules/balance-approval/balance-approval.module').then(m => m.BalanceApprovalModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false },
                    authConfig: {
                        roles: [
                            Roles.ALL,
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL
                        ]
                    }
                }
            },
            {
                path: 'exchange-flight-points/personal',
                loadChildren: () =>
                    import('./modules/exchange-flight-points/exchange-flight-points.module').then(
                        m => m.ExchangeFlightPointsModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false },
                    authConfig: {
                        roles: [
                            Roles.ALL,
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL
                        ]
                    }
                }
            },
            {
                path: 'transaction-repayment',
                loadChildren: () =>
                    import('./modules/transaction-repayment/transaction-repayment.module').then(
                        m => m.TransactionRepaymentModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'mangement-loyalty-course/personal',
                loadChildren: () =>
                    import('./modules/mangement-loyalty-course/mangement-loyalty-course.module').then(
                        m => m.MangementLoyaltyCourseModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'dept-payment',
                loadChildren: () => import('./modules/dept-payment/dept-payment.module').then(m => m.DeptModuleModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            // {
            //     path: 'new-homepage',
            //     loadChildren: () => import('./modules/new-homepage/new-homepage.module').then(m => m.NewHomepageModule),
            //     data: {
            //         minimalHedaer: true,
            //         sessionTimeoutConfig: { sessionTimeout: false }
            //     }
            // },
            {
                path: 'split-payments/personal',
                loadChildren: () =>
                    import('./modules/split-payments/split-payments.module').then(m => m.SplitPaymentsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'public-complaint-form',
                loadChildren: () =>
                    import('./modules/public-complaint-form/public-complaint-form.module').then(
                        m => m.PublicComplaintFormModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'landing-test',
                loadChildren: () => import('./modules/landing-test/landing-test.module').then(m => m.LandingTestModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'design-system',
                loadChildren: () =>
                    import('./modules/design-system/design-system.module').then(m => m.DesignSystemModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'change-course/personal',
                loadChildren: () =>
                    import('./modules/change-course/change-course.module').then(m => m.ChangeCourseModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'replace-card/personal',
                loadChildren: () => import('./modules/replace-card/replace-card.module').then(m => m.ReplaceCardModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'total-max-back/personal',
                loadChildren: () =>
                    import('./modules/total-max-back/total-max-back.module').then(m => m.TotalMaxBackModule),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS,
                            Roles.MAXBACKCLIENT
                        ]
                    }
                }
            },
            {
                path: ':dictionary-type/dictionary',
                loadChildren: () =>
                    import('./modules/dictionary-dynamic/dictionary-dynamic.module').then(m => m.DictionaryLibModule),
                data: { minimalHedaer: true }
            },
            {
                path: 'business/:dictionary-type/dictionary',
                loadChildren: () =>
                    import('./modules/dictionary-dynamic/dictionary-dynamic.module').then(m => m.DictionaryLibModule),
                data: { minimalHedaer: true }
            },
            {
                path: 'total-max-back/landing-page',
                component: MainLayoutComponent,
                children: [
                    {
                        path: '',
                        component: LandingPageComponent,
                        data: {
                            minimalHedaer: true,
                            sessionTimeoutConfig: { sessionTimeout: true }
                        }
                    }
                ]
            },

            {
                path: 'remove-round-and-donate/personal',
                loadChildren: () =>
                    import('./modules/remove-circle-for-good/remove-circle-for-good.module').then(
                        m => m.RemoveCircleForGoodModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'control-operations/personal',
                loadChildren: () =>
                    import('./modules/control-operations/control-operations.module').then(
                        m => m.ControlOperationsModule
                    ),
                data: {
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },
            {
                path: 'my-credit-products/personal',
                loadChildren: () =>
                    import('./modules/my-credit-products/my-credit-products.module').then(
                        m => m.MyCreditProductsModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL,
                            Roles.BUSINESS
                        ]
                    }
                }
            },

            {
                path: 'my-benefits/personal',
                loadChildren: () => import('./modules/my-benefits/my-benefits.module').then(m => m.MyBenefitsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true },
                    authConfig: {
                        roles: [Roles.REG, Roles.GALI, Roles.IVR, Roles.SENSITIVEIVR, Roles.WS, Roles.ADMIN, Roles.SL]
                    }
                }
            },
            {
                path: 'sales-promotion',
                loadChildren: () => import('./modules/sales-promotion/sales-promotion.module').then(m => m.SalesPromotionModule)
            },
            {
                path: 'gift-card-activation',
                loadChildren: () =>
                    import('./modules/gift-card-activate/gift-card-activation.module').then(
                        m => m.GiftCardActivationModule
                    ),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'user-details/personal',
                loadChildren: () =>
                    import('./modules/user-details/user-details.module').then(m => m.UserDetailsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false },
                    authConfig: {
                        roles: [
                            Roles.ALL,
                            Roles.REG,
                            Roles.GALI,
                            Roles.IVR,
                            Roles.SENSITIVEIVR,
                            Roles.WS,
                            Roles.ADMIN,
                            Roles.SL
                        ]
                    }
                }
            },
            {
                path: 'commbox-authorized',
                loadChildren: () =>
                    import('./modules/commbox-authorized/commbox-authorized.module').then(
                        m => m.CommboxAuthorizedModule
                    ),
                data: { sessionTimeoutConfig: { sessionTimeout: false } }
            },
            {
                path: 'gift-card-teachers-organization',
                loadChildren: () => import('./modules/gift-card-teachers-organization/gift-card-teachers-organization.module').then((m) => m.GiftCardTeachersOrganizationModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'digital-statements',
                loadChildren: () => import('./modules/digital-statements/digital-statements.module').then(m => m.DigitalStatementsModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'skygini-loader',
                loadChildren: () => import('./modules/skygini-loader/skygini-loader.module').then(m => m.SkyginiLoaderModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: false }
                }
            },
            {
                path: 'admin',
                loadChildren: () => import('./modules/admin/admin.module').then(m => m.AdminModule),
                data: {
                    minimalHedaer: true,
                    sessionTimeoutConfig: { sessionTimeout: true }
                }
            },
            {
                path: 'message-receive',
                component: MessageReceiveComponent

            },
            {
                path: '',
                loadChildren: () => import('./modules/custom-pages/custom-pages.module').then(m => m.CustomPagesModule),
                data: { sessionTimeoutConfig: { sessionTimeout: false } }
            }
        ]
    }
];
