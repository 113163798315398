import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { GenericSsoService } from "../../generic-sso.service";

@Component({
  selector: 'app-sso-with-max',
  templateUrl: './sso-with-max.component.html',
  styleUrls: ['./sso-with-max.component.scss']
})
export class SsoWithMaxComponent implements OnInit {

  constructor(private router: Router,
      private ssoService: GenericSsoService) { }

    ngOnInit(): void {
      this.ssoService.setSsoLogin();
      this.router.navigate(['login']);  }

}
